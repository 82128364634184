import Axios from "../config"

const AIChatAPI = {
    addNewPrompt: async ({userToken, channelId, promptName}) => {
        let response = await Axios
            .request({
                method: "GET",
                params: {
                    action: "get_ai_chat",
                    t: userToken,
                    s: "prompt_add",
                    i: channelId,
                    u: promptName
                }
            })

        return response.data
    }
}

export default AIChatAPI
